import React, { useState } from "react";
import LivingRoom from "../../assets/home/living-room-rug.jpg";
import Bedroom from "../../assets/home/bedroom-rug.jpg";
import Kitchen from "../../assets/home/kitchen-rug.jpg";
import Office from "../../assets/home/office-rug.jpg";
import "./space-preview.scss";

interface Space {
    space: string;
    img: string;
    klass: string;
    position: string;
}

const SPACES: Space[] = [
    {
        space: "your office",
        img: Office,
        klass: "is-50",
        position: "0vw,100vw,100vw,100vw"
    },
    {
        space: "your bedroom",
        img: Bedroom,
        klass: "is-75",
        position: "-100vw,0vw,100vw,100vw"
    },
    {
        space: "your living room",
        img: LivingRoom,
        klass: "selected",
        position: "-100vw,-100vw,0vw,100vw"
    },
    {
        space: "your kitchen",
        img: Kitchen,
        klass: "is-75",
        position: "-100vw,-100vw,-100vw,0vw"
    }
];

export const SpacePreview: React.FC = () => {
    const [spaces, space, spaceIndex, setSpace] = useSpaces();

    const renderSpaces = spaces.map((s, i) => {
        return (
            <React.Fragment key={s.space}>
                {i > 0 && <br />}
                <span className={s.klass} onClick={() => setSpace(s)}>
                    {s.space}
                </span>
            </React.Fragment>
        );
    });

    return (
        <section
            className="section is-medium"
            id="space-preview"
            style={{ backgroundPositionX: space.position }}
        >
            <div className="container is-fullhd">
                <div className="columns is-marginless">
                    <div className="column is-hidden-touch">
                        <h2 className="h1 has-text-right is-bold">
                            <br />
                            <br />
                            <br />A conscious cartpet for
                        </h2>
                    </div>
                    <div className="column">
                        <h2 className={`h1 is-bold is-${spaceIndex}`}>{renderSpaces}</h2>
                    </div>
                </div>
                {/* <Button>Shop per space</Button> */}
            </div>
        </section>
    );
};

function useSpaces(initialSpace: Space = SPACES[2]): [Space[], Space, number, Function] {
    const [spaces, setSpaces] = useState(SPACES);
    const [currentSpace, setCurrentSpace] = useState(initialSpace);
    const [index, setIndex] = useState(2);

    function setSpace(newSpace: Space) {
        setCurrentSpace(newSpace);

        const spaceIndex = spaces.findIndex((s) => s.space === newSpace.space);
        setIndex(spaceIndex);

        const newSpaces = [...spaces].map((s, si) => {
            if (si === spaceIndex - 1 || si === spaceIndex + 1) {
                return { ...s, klass: "is-75" };
            }

            if (s.space !== newSpace.space) {
                return { ...s, klass: "is-50" };
            }

            return { ...s, klass: "selected" };
        });

        setSpaces(newSpaces);
    }

    return [spaces, currentSpace, index, setSpace];
}
