import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { RouteComponentProps } from "react-router-dom";
import { HomeSlider } from "../../components/HomeSlider/HomeSlider";
import { LearnMoreContainer } from "../../components/LearnMore/LearnMoreContainer";
import { ProductDetails } from "../../components/ProductDetails/ProductDetails";
import { ProductPromo } from "../../components/ProductPromo/ProductPromo";
import { SpacePreview } from "../../components/SpacePreview/SpacePreview";
import { AiryFlower, BloomingFlower, RiverPath } from "../../products/product";
import { PageContext } from "../../providers/PageProvider";
import productDescription from "../../utils/product-description";
import { usePageView } from "../../utils/use-page-view";
import "./home-page.scss";

const TITLE = "Canary | Home";
export const HomePage: React.FC<RouteComponentProps> = ({ location: { pathname } }) => {
    const { sendEcommerceList } = useContext(PageContext);
    usePageView(pathname, TITLE);

    useEffect(() => {
        sendEcommerceList("homepage", "homepage products", [AiryFlower, BloomingFlower, RiverPath]);
        // eslint-disable-next-line
    }, []);

    return (
        <div id="home">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>

            <HomeSlider />

            <section className="section is-medium">
                <div className="container">
                    <div className="columns is-marginless">
                        <div className="column is-8 is-offset-2">
                            <h2 className="is-bold has-side-padding">Fully recyclable rugs</h2>
                            <p className="has-side-padding">
                                Our luxurious and beautiful rugs come in a variety of classy colors
                                and snazzy designs. Whatever shape, size or color you choose, all
                                our rugs are made from recycled water bottles, making them washable,
                                safe and responsible.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container is-fullhd">
                    <div className="columns is-gapless">
                        <ProductPromo
                            product={RiverPath}
                            description={productDescription(RiverPath)}
                        />
                        <ProductPromo
                            product={AiryFlower}
                            description={productDescription(AiryFlower)}
                        />
                        <ProductPromo
                            product={BloomingFlower}
                            description={productDescription(BloomingFlower)}
                        />
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="container is-fullhd">
                    <ProductDetails />
                </div>
            </section>

            <section className="section is-medium" id="we-test-everything">
                <div className="container">
                    <div className="columns is-marginless">
                        <div className="column is-8 is-offset-2">
                            <h2 className="has-side-padding is-bold">We test everything!</h2>
                            <p className="has-side-padding is-medium">
                                Our products undergo rigorous checks and tests to ensure they are as
                                safe as possible and they adhere to strict standards of health and
                                environmental stewardship that we value. We invite you to put the
                                rugs to the test yourself. We love to hear about your experiences!
                            </p>
                            <p className="has-side-padding is-bold">
                                Also, we love to get geeky, so please contact us for any questions
                                or remarks you may have!
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <SpacePreview />

            <section className="section" id="materials">
                <div className="container is-fullhd">
                    <div className="columns is-gapless">
                        <div className="column is-blue">
                            <div className="text">
                                <h2 className="h1 is-bold">Stick it Like a Gecko</h2>
                                <p>
                                    With a Canary rug, there’s no need to buy a pad or use messy
                                    tape or glue to keep it in place. Our Gecko Strips use the same
                                    micro-adhesion technology found in geckos' toes and adhere the
                                    rug to your floor without leaving marks or stains. Should the
                                    Gecko Strips lose adhesion, a quick wash with gentle soap and
                                    water will give them their powers back! The Gecko Strips have
                                    been tested to work on all types of hard flooring...as long as
                                    they are clean and not greasy &#128521;
                                </p>
                            </div>
                        </div>
                        <div className="column is-blue"></div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="container is-fullhd">
                    <div className="columns is-gapless">
                        <LearnMoreContainer />
                    </div>
                </div>
            </section>
        </div>
    );
};
