import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { RouteComponentProps } from "react-router";
import hero from "../../assets/about/hero.jpg";
import NaturalProducts from "../../assets/about/natural-products.jpg";
import weWantItBack from "../../assets/about/we-want-it-back.png";
import p1 from "../../assets/product/p1.jpg";
import { PageContext } from "../../providers/PageProvider";
import { usePageView } from "../../utils/use-page-view";
import "./about-page.scss";

const TITLE = "Canary | Transparency";

export const TransparencyPage: React.FC<RouteComponentProps> = ({ location: { pathname } }) => {
    const { sendDataLayer } = useContext(PageContext);

    usePageView(pathname, TITLE);
    useEffect(() => {
        sendDataLayer("transparency-page");
        // eslint-disable-next-line
    }, []);

    return (
        <div id="about">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <div className="header">
                {/* TODO: These pages */}
                {/* <nav className="navbar is-light" role="navigation" aria-label="main navigation">
                    <div className="navbar-brand">Our Company</div>
                    <div className="navbar-menu">
                        <div className="navbar-start">
                            <Link className="navbar-item" to="#">
                                Innovation
                            </Link>
                            <Link className="navbar-item" to="#">
                                Responsability
                            </Link>
                            <Link className="navbar-item" to="#">
                                Careers
                            </Link>
                            <Link className="navbar-item" to="#">
                                News
                            </Link>
                        </div>
                    </div>
                </nav> */}
            </div>

            <div className="main">
                <section className="section">
                    <div className="container">
                        <div className="columns">
                            <div className="column is-5">
                                <h1 className="is-bold">Radical transparency found HERE</h1>
                                <p>
                                    Why should you be surrounded by products that make you sick and
                                    make it difficult for you to breathe? Well, it is about time to
                                    say: "stop!" to this madness and demand safer home products.
                                </p>
                                <p>
                                    Our products are absolutely healthy for you and your family, as
                                    they undergo rigorous testing beyond what any certification may
                                    ever prove. We design them in collaboration with scientists and
                                    toxicologists to ensure you have the best possible product for
                                    your home.
                                </p>
                            </div>
                            <div className="column">
                                <img src={hero} alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* TODO: These reports */}
                {/* <section className="section" id="reports">
                    <div className="container">
                        <div className="columns">
                            <div className="column">
                                <h2>Sustainability Report</h2>
                                <Button to="#">Download</Button>
                            </div>
                            <div className="column">
                                <h2>The canary life circle</h2>
                                <Button to="#">Learn more</Button>
                            </div>
                        </div>
                    </div>
                </section> */}
                <section className="section">
                    <div className="container">
                        <div className="columns">
                            <div className="column">
                                <img src={p1} alt="" />
                            </div>
                            <div className="column is-5">
                                <img src={NaturalProducts} alt="" />
                                <p>
                                    Nobody should spend their precious time researching safe
                                    furniture options, and Canary has done it for you! We believe
                                    that materials matter. That's why we work with clean, healthy,
                                    recycled and recyclable materials. Good for you, great for the
                                    planet
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section" id="usps">
                    <div className="container is-fullhd">
                        <div>
                            <h2 className="h1 is-bold">Beautiful.</h2>
                        </div>
                        <div className="right">
                            <h2 className="h1 is-bold">Washable.</h2>
                        </div>
                        <div className="invert">
                            <h2 className="h1 is-bold">Safe.</h2>
                        </div>
                        <div className="right">
                            <h2 className="h1 is-bold">Responsible.</h2>
                        </div>
                    </div>
                </section>
                <section className="section is-medium">
                    <div className="container">
                        <div className="columns is-marginless">
                            <div className="column is-8 is-offset-2">
                                <h2 className="has-text-centered has-side-padding is-medium">
                                    Our <u>rugs</u> are made from polyester, one of the most{" "}
                                    <u>recyclable materials</u> on the planet. After you throw your{" "}
                                    <u>plastic water bottle</u> in the blue bin, we clean it, put it
                                    through a special process and the plastic is{" "}
                                    <u>reborn as threads</u> for the backing. It's not quite magic,
                                    but it feels like it.
                                </h2>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="again" className="section">
                    <div className="container is-gray">
                        <div className="columns is-marginless">
                            <div className="column">
                                <div className="content">
                                    <h2 className="is-bold">Again and again</h2>
                                    <p>
                                        We love the feeling of the sun on our skin, the wind in our
                                        hair and soft carpet between our toes. What we don't love is
                                        toxic stuff, pollution or waste.
                                    </p>
                                    <p>
                                        That's why our freedom squares are made from polyester
                                        (PET), one of the most recyclable materials on the planet.
                                        We make sure it does not go into the landfill or burnt, and
                                        gets recycled into premium, high quality, soft rugs for you
                                        to love again and again.
                                    </p>
                                </div>
                            </div>
                            <div className="column">
                                <img src={weWantItBack} alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <section id="responsible-consumption">
                    <div className="container"></div>
                </section>
                <section className="section is-medium" id="materials-matter">
                    <div className="container">
                        <div className="columns is-marginless">
                            <div className="column is-8 is-offset-2">
                                <h2 className="has-side-padding is-bold">Materials matter</h2>

                                <p className="has-side-padding is-medium">
                                    We have partnered with DSM-Niaga and Provenance to create the
                                    first ever blockchain-tracked rug in the world!{" "}
                                    <a
                                        href="https://www.provenance.org/assets/0x273f20ff24b789c810908ceb5684fa8cbece3700%3E"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Check it out here
                                    </a>
                                    .
                                    <br />
                                    <br />
                                    What does it mean? That we're able to provide full transparency
                                    in our supply chain. You can see all the suppliers that have
                                    made the creation of your rug possible. You can google them,
                                    check their websites and social media, you can even call them if
                                    you want! All the information is right there. Our goal, and the
                                    goal of our partners, is to work together to ensure there are no
                                    secrets when it comes to our products.
                                    <br />
                                    <br />
                                    We Canaries want to help create a healthy environment both
                                    inside and outside of your home. Our rugs are healthy and fully
                                    recyclable, making them 200% awesome for the planet. And with
                                    your help, they will never end up in a landfill or an
                                    incinerator, like 95% of the carpet and rug products sold
                                    globally.
                                    <br />
                                    <br />
                                    We say "with your help", because if you buy a Canary rug, we ask
                                    that you contact us when you're done enjoying it to let us know
                                    it is time to fly home. We'll give you discounts for a new rug
                                    and recycle it into new lovable rugs ! Why would we do that?
                                    Because we want to change the way companies make consumer goods
                                    and make the world a better, cleaner and healthier place.
                                </p>

                                {/* TODO: These pages */}
                                {/* <div className="buttons is-hidden-mobile">
                                    <Button to="#">Materials</Button>
                                    <Button to="#">Shipping</Button>
                                    <Button to="#">Products</Button>
                                    <Button to="#">Production</Button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};
