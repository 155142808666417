import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Footer } from "../../components/Footer/Footer";
import { Navbar } from "../../components/Navbar/Navbar";
import { MissionPage } from "../../pages/AboutPage/MissionPage";
import { TransparencyPage } from "../../pages/AboutPage/TransparencyPage";
import { HomePage } from "../../pages/HomePage/HomePage";
import { KickstarterPage } from "../../pages/KickstarterPage/KickstarterPage";
import { ProductPage } from "../../pages/ProductPage/ProductPage";
import { RugsPage } from "../../pages/RugsPage/RugsPage";
import ScrollToTop from "../../utils/ScrollTop";
import { PageProvider } from "../../providers/PageProvider";

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <ScrollToTop>
                <PageProvider>
                    <Route
                        path="*"
                        render={(props) => {
                            return props.location.pathname !== "/" ? <Navbar {...props} /> : null;
                        }}
                    />

                    <Switch>
                        <Route exact path="/" component={KickstarterPage} />
                        <Route exact path="/home" component={HomePage} />

                        <Route exact path="/shop/rugs" component={RugsPage} />
                        <Route path="/shop/rugs/:slug" component={ProductPage} />

                        <Route path="/transparency" component={TransparencyPage} />
                        <Route path="/mission" component={MissionPage} />
                    </Switch>

                    <Route
                        path="*"
                        render={(props) => {
                            return props.location.pathname !== "/" ? <Footer /> : null;
                        }}
                    />
                </PageProvider>
            </ScrollToTop>
        </BrowserRouter>
    );
};

export default App;
