import React, { useState } from "react";
import { Button } from "../Button/Button";
import Rug from "../../assets/home/rug-design-for-reuse.jpg";
import "./product-details.scss";

export const ProductDetails: React.FC = () => {
    const [uspOpen, toggleOpen] = useState<number>();

    const handleUsp = (i?: number) => {
        // TODO: Copy
        if (!uspOpen || uspOpen < 5) {
            return;
        }

        if (uspOpen === i) {
            toggleOpen(undefined);
        } else {
            toggleOpen(i);
        }
    };

    return (
        <div className="columns is-gapless" id="product-details">
            <div className="column is-5">
                <div className="text">
                    <h2 className="h1 is-bold">Good stuff in, great stuff out.</h2>
                    <p>
                        We love the feeling of the sun on our skin, the wind in our hair and soft
                        carpet between our toes. What we don’t love is toxic stuff, pollution or
                        waste.
                    </p>
                    <p>
                        That’s why our freedom squares are made from polyester (PET), one of the
                        most recyclable materials on the planet. We make sure it does not go into
                        the landfill or burnt, and gets recycled into premium, high quality, soft
                        rugs for you to love again and again.
                    </p>
                    <Button to="/mission">Our mission</Button>
                </div>
            </div>
            <div className="column">
                <div className="usps">
                    <img src={Rug} alt="" />
                    <USP
                        title="Design for reuse"
                        text="The material companies use to make furniture are becoming more and more complex, which makes them difficult to reuse or recycle. That's why we specifically design for reuse and recycling."
                        isOpen={uspOpen === 0}
                        toggleOpen={() => handleUsp(0)}
                    />
                    <USP
                        title="Again and again"
                        text="TODO: Text"
                        isOpen={uspOpen === 1}
                        toggleOpen={() => handleUsp(1)}
                    />
                    <USP
                        title="Keep it simple"
                        text="TODO: Text"
                        isOpen={uspOpen === 2}
                        toggleOpen={() => handleUsp(2)}
                    />
                    <USP
                        title="Materials matter"
                        text="TODO: Text"
                        isOpen={uspOpen === 3}
                        toggleOpen={() => handleUsp(3)}
                    />
                </div>
            </div>
        </div>
    );
};

interface USPProps {
    title: string;
    text: string;
    isOpen: boolean;
    toggleOpen: () => void;
}
export const USP: React.FC<USPProps> = ({ title, text, isOpen, toggleOpen }) => {
    return (
        <>
            <div className={isOpen ? "usp open" : "usp"} onClick={() => toggleOpen()}>
                <div className="plus">+</div> <span>{title}</span>
                <div className="text-box">
                    <p className="is-bold">{title}</p>
                    <p>{text}</p>
                </div>
            </div>
        </>
    );
};
