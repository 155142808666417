import * as React from "react";

const BagIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox="0 0 31.71 39.19" width="1em" height="1em" {...props}>
            <g>
                <path d="M23.51 39.19H8.2A8.21 8.21 0 010 31V15a8.21 8.21 0 018.2-8.2h15.31a8.21 8.21 0 018.2 8.2v16a8.21 8.21 0 01-8.2 8.19zM8.2 9.6A5.43 5.43 0 002.77 15v16a5.44 5.44 0 005.43 5.43h15.31A5.45 5.45 0 0029 31V15a5.44 5.44 0 00-5.49-5.4z" />
                <path d="M23.91 8.21h-2.77a5.45 5.45 0 00-10.89 0H7.48a8.22 8.22 0 0116.43 0z" />
            </g>
        </svg>
    );
};

export default BagIcon;
