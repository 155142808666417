import stepping_stones_context from "./stepping-stones-context-1-1024x682.jpg";
import stepping_stones_context_2 from "./stepping-stones-context-2-1024x682.jpg";
import stepping_stones_detail from "./stepping-stones-detail-1024x682.jpg";
import stepping_stones_detail_edge from "./stepping-stones-detail_edge-1024x682.jpg";
import stepping_stones_top from "./stepping-stones-top-1-1024x682.jpg";
import stepping_stones_top_2 from "./stepping-stones-top-2-1024x682.jpg";

const images = [
    stepping_stones_context,
    stepping_stones_top_2,
    stepping_stones_detail,
    stepping_stones_detail_edge,
    stepping_stones_context_2,
    stepping_stones_top
];

export default images;
