import React from "react";
import "./learn-more.scss";

interface LearnMoreProps {
    title: string;
    description: string;
    image?: string;
}
export const LearnMore: React.FC<LearnMoreProps> = ({ title, description, image }) => {
    return (
        <div className="column learn-more" style={{ backgroundImage: `url("${image}")` }}>
            <div>
                <h2 className="is-medium">{title}</h2>
                <p>{description}</p>
                {/* <Button>Learn more</Button> */}
            </div>
        </div>
    );
};
