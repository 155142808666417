import { Product } from "../products/product";

const productDescription = ({ colors, sizes, shapes }: Product): string => {
    const description: string[] = [];

    if (colors && colors.length) {
        description.push(descriptionText("color", colors.length));
    }

    if (shapes && shapes.length) {
        description.push(descriptionText("shape", shapes.length));
    }

    description.push(descriptionText("size", sizes.length));

    return description.join(", ");
};

function descriptionText(base: string, qty: number) {
    const item = qty === 1 ? base : `${base}s`;
    return `${qty} ${item}`;
}

export default productDescription;
