import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, RouteComponentProps } from "react-router-dom";
import BagIcon from "../../assets/icons/Bag";
import CanaryIcon from "../../assets/icons/Canary";
import { AiryFlower, BloomingFlower, RiverPath } from "../../products/product";
import { PageContext } from "../../providers/PageProvider";
import productDescription from "../../utils/product-description";
import { ProductPromo } from "../ProductPromo/ProductPromo";
import "./navbar.scss";

export const Navbar: React.FC<RouteComponentProps> = ({ location: { pathname } }) => {
    const [isMenuVisible, toggleNavbarMenu] = useState(false);
    const [isShopPath, setShopPath] = useState(false);
    const [hasShownNavbar, setHasShownNavbar] = useState(false);
    const { pageType, sendEcommerceList } = useContext(PageContext);

    useEffect(() => {
        setShopPath(pathname.includes("/shop"));
        toggleNavbarMenu(false);
        setHasShownNavbar(false);
    }, [pathname]);

    const navClass =
        isShopPath || isMenuVisible
            ? "navbar is-fixed-top is-light"
            : "navbar is-fixed-top is-transparent";

    const navbarMenuClass = isMenuVisible ? "navbar-menu is-active" : "navbar-menu";
    const navbarBurgerClass = isMenuVisible ? "navbar-burger is-active" : "navbar-burger";

    const handleOnMouseOver = () => {
        if (!hasShownNavbar) {
            sendEcommerceList(pageType, "navbar products", [AiryFlower, BloomingFlower, RiverPath]);

            setHasShownNavbar(true);
        }
    };

    return (
        <nav className={navClass} role="navigation" aria-label="main navigation">
            <div className="container is-fullhd">
                <div className="navbar-brand">
                    <Link className="navbar-item" to="/home">
                        <CanaryIcon width="112" height="28" />
                    </Link>
                    <Link
                        role="button"
                        className={navbarBurgerClass}
                        aria-label="menu"
                        aria-expanded="false"
                        to="#"
                        onClick={() => toggleNavbarMenu(!isMenuVisible)}
                    >
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </Link>
                </div>

                <div className={navbarMenuClass}>
                    <div className="navbar-start">
                        <div
                            className="navbar-item has-dropdown is-hoverable has-full-dropdown"
                            onMouseOver={handleOnMouseOver}
                        >
                            <NavLink className="navbar-link is-arrowless" to="/shop/rugs">
                                Rugs
                            </NavLink>
                            <div className="navbar-dropdown">
                                <div className="columns is-gapless">
                                    {/* TODO: These pages */}
                                    {/* <div className="column is-one-fifth is-light-blue">
                                    <div className="has-padding has-menu">
                                        <h2 className="is-medium">Our Rugs</h2>
                                        <Link className="h2" to="/shop/rugs">
                                            Rugs
                                        </Link>
                                        <Link className="h2" to="#">
                                            Great deals
                                        </Link>
                                        <Link className="h2" to="#">
                                            Shop per space
                                        </Link>
                                        <Link className="h2" to="#">
                                            Configurator
                                        </Link>
                                        <Link className="h2" to="#">
                                            Shipping
                                        </Link>
                                        <Link className="h2" to="#">
                                            Return Policy
                                        </Link>
                                    </div>
                                </div> */}
                                    <ProductPromo
                                        product={RiverPath}
                                        description={productDescription(RiverPath)}
                                    />
                                    <ProductPromo
                                        product={AiryFlower}
                                        description={productDescription(AiryFlower)}
                                    />
                                    <ProductPromo
                                        product={BloomingFlower}
                                        description={productDescription(BloomingFlower)}
                                    />
                                    <div className="column">
                                        <div className="usp has-padding">
                                            <h2 className="h2 is-bold">Machine Washable</h2>
                                            <p>
                                                Goodbye to forever stained carpet! A spill will no
                                                longer be a problem, just take the dirty piece and
                                                throw it in the washing machine!
                                            </p>
                                            {/* <Link to="#">Learn more</Link> */}
                                        </div>
                                        <div className="usp has-padding">
                                            <h2 className="h2 is-bold">Fully Recyclable</h2>
                                            <p>
                                                Our rugs are re-lovable and recyclable back into new
                                                rugs and we’ll be very happy to give you a good
                                                discount for a new lovable rug if you send them back
                                                to us!
                                            </p>
                                            {/* <Link to="#">Learn more</Link> */}
                                        </div>
                                        <div className="usp has-padding">
                                            <h2 className="h2 is-bold">Good inside &amp; out</h2>
                                            <p>
                                                Canary rugs are made with non-toxic materials so
                                                they won’t release toxic substances in your home, to
                                                the contrary! They will help trapping dust, so you
                                                will breathe less of it!
                                            </p>
                                            {/* <Link to="#">Learn more</Link> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <NavLink className="navbar-item" to="/transparency">
                            Transparency
                        </NavLink>
                        <NavLink className="navbar-item" to="/mission">
                            Mission
                        </NavLink>
                        {/* TODO: These pages */}
                        {/* <Link className="navbar-item" to="#">
                        Blog
                    </Link>
                    <Link className="navbar-item" to="#">
                        Contact
                    </Link> */}
                    </div>

                    <div className="navbar-end is-hidden-touch">
                        <div className="navbar-item">
                            <a
                                className="navbar-item"
                                href="https://shop.thecanarylife.com/cart/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <BagIcon height="28" width="28" fill="currentColor" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};
