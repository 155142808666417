import React from "react";
import "./dropdown.scss";

interface DropdownProps {
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    value?: string;
}
export const Dropdown: React.FC<DropdownProps> = ({ onChange, value, children }) => {
    return (
        <div className="canary-select">
            <select onChange={onChange} value={value}>
                {children}
            </select>
            <div className="select_arrow"></div>
        </div>
    );
};
