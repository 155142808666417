import React from "react";
import "./breadcrumbs.scss";
import { Link } from "react-router-dom";

interface BreadcrumbsProps {
    path: Breadcrumb[];
}
interface Breadcrumb {
    path: string;
    label: string;
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ path }) => {
    const renderPath = path.map((p, i) => (
        <React.Fragment key={i}>
            <Link to={p.path}>{p.label}</Link>
        </React.Fragment>
    ));

    return <div className="breadcrumbs">{renderPath}</div>;
};
