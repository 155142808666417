import numeral from "numeral";
import { DataLayerArgs } from "react-gtm-module";
import { Product } from "../products/product";

export const PAGE_VIEW_EVENT = "spaPageView";

interface PageView {
    pagePath: string;
    pageTitle: string;
}
export function createPageView(data: PageView) {
    return createDataLayer({
        event: PAGE_VIEW_EVENT,
        ...data
    });
}

export function createDataLayer(data: object): DataLayerArgs {
    return {
        dataLayer: {
            language: "en",
            currency: "USD",
            ...data
        }
    };
}

export interface ProductVariation {
    id: string;
    price: string;
    variant: string; // size
    dimension1: string; // color or shape
    dimension2: string; // sale description
    metric1: string; // current price of sale
    metric2: string; // difference between original price and metric1
}
export interface ProductVariationProps {
    id: number | string;
    price: number;
    size: string; // size
    dimension1: string; // color or shape
}
export function createProductVariation(variation: ProductVariationProps): ProductVariation {
    const { id, price, size, dimension1 } = variation;
    return {
        id: formatString(`${id}`),
        price: formatPrice(price),
        variant: formatSize(size),
        dimension1: formatString(dimension1),
        metric1: formatPrice(price),
        metric2: "0",
        dimension2: ""
    };
}
function createStandardVariation(product: Product): ProductVariation {
    const { id, price } = product.actions[Object.keys(product.actions)[0]];

    return {
        id: formatString(`${id}`),
        price: formatPrice(price),
        metric1: formatPrice(price),
        metric2: "0",
        variant: "",
        dimension1: "",
        dimension2: ""
    };
}

interface ProductLayer {
    name: string;
    id: string | string;
    price: string;
    category: string;
    brand: string;
    variant: string;
    dimension1: string;
    dimension2: string;
    metric1: string;
    metric2: string;
    list?: string;
    position?: number;
}
export function createProduct(
    product: Product,
    variation?: ProductVariation,
    position?: number,
    list?: string
): ProductLayer {
    if (!variation) {
        variation = createStandardVariation(product);
    }

    const { name, price, category, material } = product;

    return {
        name: formatString(name),
        price: formatPrice(price),
        category: formatString(category),
        brand: formatString(material),
        ...variation,
        list,
        position
    };
}

export function createProducts(
    listName: string,
    products: Product[],
    variations: ProductVariation[] = []
) {
    return products.map((p, i) => createProduct(p, variations[i], i + 1, listName));
}

function formatString(value: string): string {
    return value.toLowerCase();
}

function formatPrice(value: number): string {
    return numeral(value).format("0,00");
}

function formatSize(value: string): string {
    return value.replace(/( |')/g, "");
}

interface EcommerceEvent {
    event: "ecommerceEvent";
    eventCategory: "enhanced ecommerce";
    eventAction: string;
    eventLabel: string; // dynamic value
    eventNonInteraction: boolean;
    ecommerce: {
        impressions?: any;
        detail?: any;
    };
}
interface EcommerceImpression extends EcommerceEvent {
    eventAction: "product impressions";
    eventLabel: string; // dynamic value, list name
    eventNonInteraction: true;
    ecommerce: {
        impressions: ProductLayer[];
    };
}

export function createEcommerceList(
    listName: string,
    products: Product[],
    variations: ProductVariation[] = []
): EcommerceImpression | undefined {
    if (!products.length) {
        return;
    }

    return {
        event: "ecommerceEvent",
        eventCategory: "enhanced ecommerce",
        eventAction: "product impressions",
        eventLabel: listName,
        eventNonInteraction: true,
        ecommerce: {
            impressions: createProducts(listName, products, variations)
        }
    };
}

interface ProductDetail {
    actionField?: {
        list: string; // dynamic value
    };
    products: ProductLayer[];
}
interface EcommerceDetail extends EcommerceEvent {
    eventAction: "product detail";
    eventLabel: string; // dynamic value, product name
    eventNonInteraction: true;
    ecommerce: {
        detail: ProductDetail;
    };
}

export function createEcommerceDetail(
    product: Product,
    variation?: ProductVariation,
    listName?: string
): EcommerceDetail {
    let detail: EcommerceDetail = {
        event: "ecommerceEvent",
        eventCategory: "enhanced ecommerce",
        eventAction: "product detail",
        eventLabel: formatString(product.name),
        eventNonInteraction: true,
        ecommerce: {
            detail: {
                products: [createProduct(product, variation, undefined, listName)]
            }
        }
    };

    if (listName) {
        detail = {
            ...detail,
            ecommerce: { detail: { ...detail.ecommerce.detail, actionField: { list: listName } } }
        };
    }

    return detail;
}
