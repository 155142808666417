import React, { useState, useEffect } from "react";
import Slider, { Settings } from "react-slick";
import "./product-images-slider.scss";
import { Product } from "../../products/product";

interface ProductImagesSliderProps {
    product: Product;
}
export const ProductImagesSlider: React.FC<ProductImagesSliderProps> = ({ product }) => {
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();

    const [images, setImages] = useState(product.images.all);
    useEffect(() => {
        setImages(product.images.all);

        if (nav1 && nav2) {
            nav1.slickGoTo(0);
            nav2.slickGoTo(0);
        }
    }, [product.slug, product.images.all, nav1, nav2]);

    const nav1Settings: Settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: nav2
    };

    const nav2Settings: Settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        asNavFor: nav1,
        swipeToSlide: true,
        focusOnSelect: true
    };

    const renderImages = images.map((img) => {
        return (
            <div key={img}>
                <img src={img} alt="" />
            </div>
        );
    });

    return (
        <div id="product-images-slider">
            <Slider {...nav1Settings} ref={(slider) => setNav1(slider)}>
                {renderImages}
            </Slider>
            <Slider {...nav2Settings} ref={(slider) => setNav2(slider)}>
                {renderImages}
            </Slider>
        </div>
    );
};
