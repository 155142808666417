import * as React from "react";

const PetIconIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox="0 0 105.84 105.84" width="1em" height="1em" {...props}>
            <circle
                cx={52.92}
                cy={52.92}
                fill="#fff"
                r={51.5}
                stroke="#ffcd00"
                strokeMiterlimit={10}
                strokeWidth={2.84}
            />
            <g fill="none" stroke="#010101">
                <g strokeWidth={4.09}>
                    <path
                        d="M48.34 25c-5.22 2.32-8.18 8.8-8.18 14.32s2.05 8.18 2.05 8.18v16.39s-2.05 3-2.05 4.09v8.18a3.81 3.81 0 004.09 4.09h16.36a3.81 3.81 0 004.09-4.09V68c0-1.07-2-4.09-2-4.09V47.53s2-2.67 2-8.18-3-12-8.18-14.32"
                        strokeMiterlimit={10}
                    />
                    <path d="M48.34 18.9h8.18v6.13h-8.18z" strokeLinejoin="round" />
                    <g strokeMiterlimit={10}>
                        <path d="M40.62 35.26h23.57M42.21 47.53h20.45" />
                    </g>
                </g>
                <path d="M42.21 63.89h20.45" strokeMiterlimit={10} strokeWidth={2.9} />
            </g>
        </svg>
    );
};

export default PetIconIcon;
