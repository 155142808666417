import * as React from "react";

const HealthyIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox="0 0 105.79 90.69" width="1em" height="1em" {...props}>
            <path
                d="M52.57 89.83c-9.28-9.22-18.78-18.65-28.27-28.1-4.93-4.91-9.88-9.81-14.74-14.79-4-4.09-7.14-8.73-8.28-14.45C-.94 21.28 5.81 8.72 16.68 3.64 28-1.64 40.88.77 51.08 10.06c.58.53 1.15 1.08 1.78 1.68a36.32 36.32 0 0117.9-10.21c17.44-3.86 34.33 9.54 34.17 27.33A22.93 22.93 0 0198 45C83 60 67.77 74.85 52.57 89.83zm.31-67.68a45.25 45.25 0 00-15.56-12C22.75 3.59 8.08 14.9 8.23 28.54c.07 5.63 3.27 10 7 13.76 12 12.15 24.15 24.19 36.24 36.26.42.42.9.78 1.41 1.22.47-.41.89-.74 1.25-1.11C66 66.85 77.89 55.08 89.61 43.13a32.36 32.36 0 006-8.32 16.12 16.12 0 001.75-8.29C95.78 13.05 82.68 5.09 70 9.63c-6.86 2.43-12.26 6.86-17.12 12.52z"
                fill="#ffcd00"
            />
            <path
                d="M52.57 90.69a.85.85 0 01-.61-.25l-8.18-8.12-20.09-20-1.79-1.76c-4.26-4.24-8.68-8.63-13-13-4.69-4.8-7.47-9.67-8.5-14.88a26.21 26.21 0 012.87-17.3 28.76 28.76 0 0113-12.5A29.71 29.71 0 0134.45.54a35.43 35.43 0 0117.21 8.89c.4.36.79.73 1.19 1.12A36.71 36.71 0 0170.58.69a28.92 28.92 0 0124.58 5.85 28.3 28.3 0 0110.63 22.33 23.74 23.74 0 01-7.22 16.73c-10.85 10.9-22 21.86-32.81 32.47-4.13 4.05-8.39 8.24-12.58 12.37a.85.85 0 01-.61.25zM29 1.75a28 28 0 00-11.9 2.67A27.06 27.06 0 004.83 16.18a24.5 24.5 0 00-2.7 16.15c1 4.94 3.54 9.39 8.05 14 4.27 4.37 8.68 8.76 12.94 13l1.79 1.78c6.72 6.69 13.52 13.44 20.09 20l7.57 7.52 12-11.77c10.79-10.6 22-21.57 32.8-32.46a22 22 0 006.71-15.53 26.56 26.56 0 00-10-21A27.23 27.23 0 0071 2.37c-6.42 1.43-12.13 4.69-17.48 10a.87.87 0 01-1.2 0l-.6-.57-1.22-1.1C44 4.8 36.47 1.75 29 1.75zm24 78.89a.89.89 0 01-.56-.21l-.57-.43a10.27 10.27 0 01-1-.87l-9-9C32.9 61.25 23.67 52 14.64 42.91a30 30 0 01-5-6.38 16.2 16.2 0 01-2.24-8A21.32 21.32 0 0137.67 9.4a45.52 45.52 0 0115.2 11.44c5.32-6 10.67-9.84 16.79-12a21.53 21.53 0 0118.75 1.85 21.15 21.15 0 019.75 15.76 16.55 16.55 0 01-1.83 8.76 32.81 32.81 0 01-6.11 8.55c-8.81 9-17.88 18-26.65 26.76l-8.81 8.78a10.36 10.36 0 01-.85.77c-.14.12-.29.24-.44.38a.86.86 0 01-.57.19zM28.58 9.11A19.2 19.2 0 0018 12.29a19.43 19.43 0 00-8.9 16.24c.06 5.64 3.46 9.82 6.77 13.17 9 9.12 18.26 18.33 27.19 27.23l9 9a9.33 9.33 0 00.79.7 7.79 7.79 0 00.65-.59l8.82-8.79c8.8-8.71 17.86-17.74 26.68-26.72a31.18 31.18 0 005.79-8.09 15.16 15.16 0 001.67-7.82 19.47 19.47 0 00-9-14.49 19.86 19.86 0 00-17.26-1.69c-6.07 2.17-11.38 6.06-16.71 12.27a.85.85 0 01-1.3 0A44.59 44.59 0 0037 11a20.3 20.3 0 00-8.42-1.89z"
                fill="#ffcd00"
            />
            <path
                d="M56.3 57.75L39.49 40.67l5.11-4.51L56 47.67l27.32-27.48 5 5.47z"
                fill="#010101"
            />
            <path
                d="M56.3 58.61a.9.9 0 01-.62-.25l-16.8-17.08a.85.85 0 01-.25-.64.88.88 0 01.29-.61L44 35.51a.86.86 0 011.18 0L56 46.45l26.71-26.87a.85.85 0 011.25 0l5 5.47a.88.88 0 010 1.19l-32 32.09a.86.86 0 01-.66.28zM40.75 40.72L56.3 56.53l30.79-30.9-3.8-4.19-26.73 26.84a.86.86 0 01-.61.25.9.9 0 01-.62-.25L44.56 37.34z"
                fill="#231f20"
            />
        </svg>
    );
};

export default HealthyIcon;
