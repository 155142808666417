import * as React from "react";

const RecycleIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox="0 0 102.34 95.23" width="1em" height="1em" {...props}>
            <path
                d="M62.26 0h-24.9l-16 27.73L40 38.51zM58 33l-5.14 3 22.34 5.19 6.61-22-5.13 3L65.41 2.66 53 24.22zm31.88 54.89l12.46-21.55-16-27.73L67.65 49.4zm-26.4-20.16V61.8L47.75 78.51l15.73 16.72V89.3H86L73.57 67.73zm-19 21.6V67.76H0l12.43 21.57zM.71 63.72h24.91l5-8.74 5.13 3-6.6-22-22.3 5.25 5.13 3z"
                fill="#ffcd00"
            />
        </svg>
    );
};

export default RecycleIcon;
