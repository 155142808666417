import AiryFlowerImages from "../assets/product/airy-flower";
import AiryFlowerCover from "../assets/product/airy-flower/airy-flower.jpg";
import AiryFlowerSlider from "../assets/product/airy-flower/airy-flower.png";
import BloomingFlowerImages from "../assets/product/blooming-flower";
import BloomingFlowerCover from "../assets/product/blooming-flower/blooming-flower.jpg";
import BloomingFlowerSlider from "../assets/product/blooming-flower/blooming-flower.png";
import RiverPathImages from "../assets/product/river-path";
import RiverPathCover from "../assets/product/river-path/river-path.jpg";
import RiverPathSlider from "../assets/product/river-path/river-path.png";

export interface Product {
    name: string;
    slug: string;
    price: number;
    category: string;
    material: string;
    description: string;
    shortDescription: string;
    sizes: string[];
    colors?: string[];
    shapes?: string[];
    images: ProductImages;
    actions: {
        [key: string]: {
            id: number | string;
            price: number;
        };
    };
    initialAction: {
        id: number | string;
        price: number;
        size: string;
        color?: string;
        shape?: string;
    };
}

interface ProductImages {
    cover: string;
    slider: string;
    all: string[];
}

type Products = Product[];

export const AiryFlower: Product = {
    name: "Airy Flowers",
    slug: "airy-flowers",
    price: 99,
    category: "rug",
    material: "water bottles",
    description:
        "Our flower field collection brings that magical feeling of spring to any room.  And since our rugs so easy to take care of and good for the planet, they’ll give you that warm fuzzy feeling over and over again.   ",
    shortDescription:
        "Our flower field collection brings that magical feeling of spring to any room.",
    sizes: ["3' x 3'"],
    shapes: ["Daisy", "Lily", "Tulip", "Aster"],
    images: {
        cover: AiryFlowerCover,
        slider: AiryFlowerSlider,
        all: AiryFlowerImages
    },
    actions: {
        "3' x 3' Daisy": { id: 34672914, price: 99 },
        "3' x 3' Lily": { id: 34672916, price: 99 },
        "3' x 3' Tulip": { id: 34672917, price: 99 },
        "3' x 3' Aster": { id: 34672915, price: 99 }
    },
    initialAction: {
        id: 34672914,
        price: 99,
        size: "3' x 3'",
        shape: "Daisy"
    }
};

export const BloomingFlower: Product = {
    name: "Blooming Flower",
    slug: "blooming-flower",
    price: 699,
    category: "rug",
    material: "water bottles",
    description:
        "Inspired by the energetic lines and symmetries of flower petals, our Blooming Flower provides a dynamic focal point for any room or play area. And because it’s non-toxic and made of super soft polyester (one of the cleanest materials around), it doesn’t just look good, it’s good for you too.",
    shortDescription: "Blooming Flower provides a dynamic focal point for any room or play area.",
    sizes: ["8' x 8'"],
    images: {
        cover: BloomingFlowerCover,
        slider: BloomingFlowerSlider,
        all: BloomingFlowerImages
    },
    actions: {
        "8' x 8'": { id: 34630241, price: 699 }
    },
    initialAction: {
        id: 34630241,
        price: 699,
        size: "8' x 8'"
    }
};

export const RiverPath: Product = {
    name: "River Path",
    slug: "river-path",
    price: 59,
    category: "rug",
    material: "water bottles",
    description:
        "Give your home the feeling of a Zen retreat with the River Path rug.  It's ultra-soft texture will provide a nice respite at the end of the day and the different sizes and colors are easily mixed and matched to create the perfect design for any room.",
    shortDescription: "Give your home the feeling of a Zen retreat with the River Path rug.",
    colors: ["Gray", "White"],
    sizes: ["1.5' x 1.5'", "2.5' x 2.5'", "3.5' x 3.5'"],
    images: {
        cover: RiverPathCover,
        slider: RiverPathSlider,
        all: RiverPathImages
    },
    actions: {
        "Gray 1.5' x 1.5'": { id: 34673072, price: 59 },
        "Gray 2.5' x 2.5'": { id: 34673072, price: 69 },
        "Gray 3.5' x 3.5'": { id: 34673072, price: 99 },
        "White 1.5' x 1.5'": { id: 34673075, price: 59 },
        "White 2.5' x 2.5'": { id: 34673076, price: 69 },
        "White 3.5' x 3.5'": { id: 34673077, price: 99 }
    },
    initialAction: {
        id: 34673072,
        price: 59,
        size: "1.5' x 1.5'",
        color: "Gray"
    }
};

export const Products: Products = [RiverPath, AiryFlower, BloomingFlower];

export function productWithSlug(slug: string): Product {
    return Products.find((p) => p.slug === slug) || Products[0];
}

export function allProductsExcept(slug: string): Product[] {
    const productIndex = Products.findIndex((p) => p.slug === slug);
    const products = [...Products];
    products.splice(productIndex, 1);
    return products;
}
