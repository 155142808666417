import React from "react";
import { Button } from "../Button/Button";
import Slider, { Settings } from "react-slick";
import "./home-slider.scss";

export const HomeSlider: React.FC = () => {
    const settings: Settings = {
        dots: true,
        arrows: false,

        infinite: true,
        autoplay: false,
        autoplaySpeed: 3000,
        speed: 500,

        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0
    };

    return (
        <Slider {...settings}>
            <section className="hero is-fullheight is-dark">
                <div className="hero-body">
                    <div className="container">
                        <h1 className="is-bold">
                            A human home is like a Canary’s nest; everything has to be just right
                        </h1>
                        <p className="is-medium">
                            Any space can be a nest with Canary Life’s beautiful, safe and washable
                            rugs
                        </p>
                        <Button to="/shop/rugs">Create your Canary nest now</Button>
                    </div>
                </div>
            </section>
            <section className="hero is-fullheight is-dark">
                <div className="hero-body">
                    <div className="container">
                        <h1 className="is-bold">
                            We play with design, not with your health or the planet
                        </h1>
                        <p className="is-medium">
                            Canary Life’s luxurious, environmentally-conscious and modular rugs add
                            a playful touch to your city your home
                        </p>
                        <Button to="/shop/rugs">Design your space</Button>
                    </div>
                </div>
            </section>
        </Slider>
    );
};
