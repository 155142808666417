import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { RouteComponentProps } from "react-router-dom";
import { ProductPromo } from "../../components/ProductPromo/ProductPromo";
import { AiryFlower, BloomingFlower, RiverPath } from "../../products/product";
import { PageContext } from "../../providers/PageProvider";
import productDescription from "../../utils/product-description";
import { usePageView } from "../../utils/use-page-view";
import "./rugs.scss";

const TITLE = "Canary | Rugs";
export const RugsPage: React.FC<RouteComponentProps> = ({ location: { pathname } }) => {
    const { sendEcommerceList } = useContext(PageContext);
    usePageView(pathname, TITLE);
    sendEcommerceList("products-page", "all products", [AiryFlower, BloomingFlower, RiverPath]);

    return (
        <div id="rugs">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>

            <section className="section">
                <div className="container">
                    <div className="columns">
                        <div className="column is-9">
                            <h1 className="is-bold">Rugs made from water bottles</h1>
                            <h2 className="is-medium">
                                Our luxurious and beautiful rugs come in a variety of classy colors
                                and snazzy designs. Whatever shape, size or color you choose, all
                                our rugs are made from recycled water bottles, making them washable,
                                safe and responsible. 
                            </h2>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="container">
                    <div className="columns">
                        <ProductPromo
                            product={RiverPath}
                            description={productDescription(RiverPath)}
                        />

                        <ProductPromo
                            product={AiryFlower}
                            description={productDescription(AiryFlower)}
                        />
                        <ProductPromo
                            product={BloomingFlower}
                            description={productDescription(BloomingFlower)}
                        />
                    </div>
                </div>
            </section>
        </div>
    );
};
