import * as React from "react";

const TickIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox="0 0 29.76 31.28" width="1em" height="1em" {...props}>
            <path
                d="M27.36 1.8l-17.99 24-6.75-12"
                fill="none"
                stroke="#ffcd00"
                strokeMiterlimit={10}
                strokeWidth={6}
            />
        </svg>
    );
};

export default TickIcon;
