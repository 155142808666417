import React, { createContext, useState } from "react";
import TagManager from "react-gtm-module";
import { Product } from "../products/product";
import {
    createDataLayer,
    createEcommerceDetail,
    createEcommerceList,
    ProductVariation
} from "../utils/data-layer";

export interface Page {
    pageType: string;
    sendEcommerceList: (pageType: string, list: string, products: Product[]) => void;
    sendEcommerceDetail: (
        pageType: string,
        product: Product,
        variation?: ProductVariation,
        list?: string
    ) => void;
    sendDataLayer: (pageType: string, data?: any) => void;
}

const PageContext = createContext<Page>({
    pageType: "homepage",
    sendEcommerceList: () => {},
    sendEcommerceDetail: () => {},
    sendDataLayer: () => {}
});

const PageProvider: React.FC = ({ children }) => {
    const [pageType, setPageType] = useState<string>("homepage");

    const sendEcommerceList = (pageType: string, list: string, products: Product[]) => {
        const ecommerce = createEcommerceList(list, products);
        sendDataLayer(pageType, ecommerce);
    };

    const sendEcommerceDetail = (
        pageType: string,
        product: Product,
        variation?: ProductVariation,
        list?: string
    ) => {
        const ecommerce = createEcommerceDetail(product, variation, list);
        sendDataLayer(pageType, ecommerce);
    };

    const sendDataLayer = (pageType: string, data?: any) => {
        setPageType(pageType);

        const dataLayer = createDataLayer({
            pageType,
            ...data
        });

        TagManager.dataLayer(dataLayer);
    };

    return (
        <PageContext.Provider
            value={{
                pageType,
                sendEcommerceList,
                sendEcommerceDetail,
                sendDataLayer
            }}
        >
            {children}
        </PageContext.Provider>
    );
};

export { PageContext, PageProvider };
