import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { RouteComponentProps } from "react-router";
import HealthyIcon from "../../assets/icons/Healthy";
import RecycleIcon from "../../assets/icons/Recycle";
import WashableIcon from "../../assets/icons/Washable";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { LearnMoreContainer } from "../../components/LearnMore/LearnMoreContainer";
import { ProductForm } from "../../components/ProductForm/ProductForm";
import { ProductImagesSlider } from "../../components/ProductImagesSlider/ProductImagesSlider";
import { ProductPromo } from "../../components/ProductPromo/ProductPromo";
import { ProductSlider } from "../../components/ProductSlider/ProductSlider";
import { SpacePreview } from "../../components/SpacePreview/SpacePreview";
import { allProductsExcept, Product, productWithSlug } from "../../products/product";
import { PageContext } from "../../providers/PageProvider";
import { usePageView } from "../../utils/use-page-view";
import "./product-page.scss";
import { createProductVariation } from "../../utils/data-layer";

interface ProductParams {
    category: string;
    slug: string;
}
export const ProductPage: React.FC<RouteComponentProps<ProductParams>> = ({
    match: { params },
    location: { pathname }
}) => {
    const { sendEcommerceList, sendEcommerceDetail } = useContext(PageContext);

    const product = productWithSlug(params.slug);
    const title = `Canary | ${product.name}`;
    usePageView(pathname, title);

    const [otherProducts, setOtherProducts] = useState<Product[]>([]);
    const [price, setPrice] = useState(product.price);
    useEffect(() => {
        setPrice(product.price);

        const other = allProductsExcept(product.slug);
        setOtherProducts(other);

        sendEcommerceList("product-page", "related products", other);

        const dimension1 = product.initialAction.color || product.initialAction.shape || "";

        const variation = createProductVariation({
            id: product.initialAction.id,
            price: product.initialAction.price,
            size: product.initialAction.size,
            dimension1
        });
        sendEcommerceDetail("product-page", product, variation);
        // eslint-disable-next-line
    }, [product.slug, product.price]);

    const renderOtherProducts = otherProducts.map((p) => (
        <ProductPromo key={p.slug} product={p} description={p.shortDescription} />
    ));

    return (
        <div id="product">
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <ProductSlider product={product} price={price} />

            <section>
                <div className="container">
                    <Breadcrumbs
                        path={[
                            { path: "/shop/rugs", label: "Shop" },
                            { path: "/shop/rugs", label: "Rugs" },
                            { path: `/shop/rugs/${product.slug}`, label: product.name }
                        ]}
                    />

                    <h1 className="is-bold title">{product.name}</h1>

                    <div className="columns is-marginless">
                        <div className="column is-6 images">
                            <ProductImagesSlider product={product} />
                        </div>
                        <div className="column is-5 is-offset-1">
                            <ProductForm product={product} onPriceChange={setPrice} />
                        </div>
                    </div>
                </div>
            </section>

            {/* TODO: Bundles */}
            {/* <section className="section split-1-3">
                <div className="container is-fullhd">
                    <div className="columns is-marginless">
                        <div className="column is-5 is-blue with-content">
                            <div className="content">
                                <h2 className="h1 is-bold">Bundle offer</h2>
                                <p>
                                    2x Dark Grey (1.5’ x 1.5’)
                                    <br />
                                    2x Light grey (1.5’ x 1.5’)
                                    <br />
                                    1x Dark Grey (2.5’ x 2.5’)
                                    <br />
                                    1x Light grey (2.5’ x 2.5’)
                                    <br />
                                    1x Dark Grey (3.5’ x 3.5’)
                                    <br />
                                </p>
                                <h2 className="is-bold">$356.93</h2>
                                <Button>Buy now</Button>
                            </div>
                        </div>
                        <div className="column image bundle"></div>
                    </div>
                </div>
            </section> */}

            <section className="section split-1-3">
                <div className="container is-fullhd is-gray">
                    <div className="columns is-marginless">
                        <div className="column image baby is-hidden-mobile"></div>
                        <div className="column is-7-tablet is-5">
                            <div className="usps">
                                <div className="columns">
                                    <div className="column is-2 column-icon">
                                        <div className="icon">
                                            <WashableIcon />
                                        </div>
                                    </div>
                                    <div className="column is-9">
                                        <h2 className="is-bold">Machine Washable</h2>
                                        <p>
                                            Goodbye to weird carpet shampoos, sprays and forever
                                            stained carpet. Whether you have kids, pets or you’re
                                            simply a red wine enthusiast, a spill will no longer be
                                            a problem! Just take the dirty piece and throw it in the
                                            washing machine! A cold wash setting and gentle laundry
                                            detergent will do the trick.
                                        </p>
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column is-2 column-icon">
                                        <div className="icon">
                                            <RecycleIcon />
                                        </div>
                                    </div>
                                    <div className="column">
                                        <h2 className="is-bold">Fully Recyclable</h2>
                                        <p>
                                            Never ever put our rugs in the trash! If you are ever
                                            tired of our hypoallergenic, odor-free, easy to clean,
                                            washable rugs, please let Canary know it is time for
                                            them to fly home! Our rugs are re-lovable and recyclable
                                            back into new rugs and we’ll be very happy to give them
                                            love once you are tired of them. Send the rug back when
                                            you're done enjoying it, and we'll give you a good
                                            discount for a new lovable rug.
                                        </p>
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column is-2 column-icon">
                                        <div className="icon">
                                            <HealthyIcon />
                                        </div>
                                    </div>
                                    <div className="column">
                                        <h2 className="is-bold">Good inside &amp; out</h2>
                                        <p>
                                            What're 3000 gallons? Ah! It’s the amount of air you
                                            breathe per day!
                                            <br />
                                            Did you know that 95% of furniture products release
                                            toxic substances in the air? That is the air you breathe
                                            at home or at work, and it should not be this way.
                                            <br />
                                            Canary rugs are made with non-toxic materials so they
                                            won’t release toxic substances in your home, to the
                                            contrary! They will help trapping dust, so you will
                                            breathe less of it!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <SpacePreview />

            <div className="container separator">
                <h2 className="h1 is-bold">You may also like</h2>
            </div>

            <section>
                <div className="container">
                    <div className="columns is-gapless">{renderOtherProducts}</div>
                </div>
            </section>

            <section className="section" id="better-together">
                <div className="container is-blue">
                    <div className="content">
                        <h2 className="h1">#BetterTogether</h2>
                        <p>
                            Our rugs are wonderful design compositions and take shape when placed
                            together in a room. Imagine an element of soft art on your floor, such
                            as a touch of zen with the River Path rug composition.
                        </p>
                        {/* <Button>Learn more</Button> */}
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="container is-fullhd">
                    <div className="columns is-gapless">
                        <LearnMoreContainer />
                    </div>
                </div>
            </section>
        </div>
    );
};
