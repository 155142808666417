import flower_field_context_1 from "./flower_field_context_1-1024x682.jpg";
import flower_field_context_2 from "./flower_field_context_2-1024x682.jpg";
import flower_field_context_3 from "./flower_field_context_3-1024x682.jpg";
import flower_field_detail from "./flower_field_detail-1024x682.jpg";
import flower_field_detail_edge from "./flower_field_detail_edge-1024x682.jpg";
import flower_field_top from "./flower_field_top-1024x682.jpg";
import flower_field_top_1 from "./flower_field_top_1-1024x682.jpg";
import flower_field_top_2 from "./flower_field_top_2-1024x682.jpg";
import flower_field_top_3 from "./flower_field_top_3-1024x682.jpg";
import flower_field_top_4 from "./flower_field_top_4-1024x682.jpg";

const images = [
    flower_field_context_1,
    flower_field_top,
    flower_field_detail,
    flower_field_detail_edge,
    flower_field_context_2,
    flower_field_context_3,
    flower_field_top_1,
    flower_field_top_2,
    flower_field_top_3,
    flower_field_top_4
];

export default images;
