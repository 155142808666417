// Polyfills for ie11
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import ReactPixel from "react-facebook-pixel";
import TagManager from "react-gtm-module";
import App from "./containers/App/App";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    const tagManagerArgs = {
        gtmId: "GTM-TH76F3"
    };

    TagManager.initialize(tagManagerArgs);
} else {
    const tagManagerArgs = {
        gtmId: "GTM-NDLBG57"
    };

    TagManager.initialize(tagManagerArgs);

    ReactPixel.init("1417104965098892");
    ReactPixel.pageView();
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
