import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LeftArrowIcon from "../../assets/icons/LeftArrow";
import RightArrowIcon from "../../assets/icons/RightArrow";
import { Product, Products } from "../../products/product";
import { centerProducts } from "../../utils/center-products";
import "./product-slider.scss";

interface ProductSliderProps {
    product: Product;
    price?: number;
}
export const ProductSlider: React.FC<ProductSliderProps> = ({ product, price }) => {
    const [orderedProducts, setOrderedProducts] = useState([...Products]);
    useEffect(() => {
        const products = centerProducts(Products, product.slug);
        setOrderedProducts(products);
    }, [product.slug]);

    const renderProducts = orderedProducts.map((p, i) => {
        return (
            <img
                src={p.images.slider}
                alt={p.name}
                key={p.slug}
                className={i === 1 ? `${p.slug} center` : p.slug}
            />
        );
    });

    return (
        <div id="products-slide">
            <div className="caption">
                <h1>{product.name}</h1>
                <h2>From ${price || product.price}</h2>
            </div>

            <div className="products">
                <div className="arrows">
                    <Link to={`/shop/rugs/${orderedProducts[0].slug}`}>
                        <LeftArrowIcon className="left-arrow" />
                    </Link>

                    <Link to={`/shop/rugs/${orderedProducts[2].slug}`}>
                        <RightArrowIcon className="left-arrow" />
                    </Link>
                </div>

                <div className="product-images">{renderProducts}</div>
            </div>
        </div>
    );
};
