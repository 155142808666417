import React from "react";
import { Link } from "react-router-dom";
import PetIconIcon from "../../assets/icons/PetIcon";
import { Product } from "../../products/product";
import { Button } from "../Button/Button";
import "./product-promo.scss";

interface ProductPromoProps {
    product: Product;
    description: string;
}
export const ProductPromo: React.FC<ProductPromoProps> = ({ product, description }) => {
    const {
        name,
        slug,
        price,
        description: pDesc,
        images: { cover }
    } = product;

    return (
        <div className="column">
            <div className="product-promo">
                <Link to={`/shop/rugs/${slug}`}>
                    <img src={cover} alt={pDesc} />
                </Link>
                <div className="pet">
                    <PetIconIcon width="60" height="60" />
                </div>
                <div className="caption">
                    <h2 className="is-bold">{name}</h2>
                    <p className="is-bold">{description}</p>

                    <div className="is-flex">
                        <Button to={`/shop/rugs/${slug}`}>Buy now</Button>
                        <h2 className="h1 is-bold price">${price}</h2>
                    </div>
                </div>
            </div>
        </div>
    );
};
