import * as React from "react";

const LeftArrowIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox="0 0 174.83 57.04" width="1em" height="1em" {...props}>
            <path
                d="M172 28.52H6.82"
                fill="none"
                stroke="#010101"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5.67}
            />
            <path
                d="M45.56 1.31a2.85 2.85 0 01-.87 3.92L8.11 28.52l36.58 23.3a2.83 2.83 0 11-3 4.78L1.31 30.92a2.84 2.84 0 010-4.79L41.65.44A2.82 2.82 0 0143.17 0a2.85 2.85 0 012.39 1.31z"
                fill="#010101"
            />
        </svg>
    );
};

export default LeftArrowIcon;
