import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { createPageView } from "./data-layer";

export function usePageView(pagePath: string, pageTitle?: string) {
    useEffect(() => {
        const dataLayer = createPageView({
            pageTitle: pageTitle || document.title,
            pagePath
        });

        TagManager.dataLayer(dataLayer);
    }, [pagePath, pageTitle]);
}
