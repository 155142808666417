import React, { useContext, useEffect, useState } from "react";
import TickIcon from "../../assets/icons/Tick";
import { Product } from "../../products/product";
import { PageContext } from "../../providers/PageProvider";
import { Button } from "../Button/Button";
import { ColorPicker } from "../ColorPicker/ColorPicker";
import { Dropdown } from "../Dropdown/Dropdown";
import { createProductVariation } from "../../utils/data-layer";

interface ProductFormProps {
    product: Product;
    onPriceChange?: (price: number) => void;
}
export const ProductForm: React.FC<ProductFormProps> = ({ product, onPriceChange }) => {
    const { pageType, sendEcommerceDetail } = useContext(PageContext);

    const [id, setId] = useState<string | number>();

    const [price, setPrice] = useState<number>();
    const [size, setSize] = useState<string>(product.sizes[0]);
    const [shape, setShape] = useState<string>();
    const [color, setColor] = useState<string>();

    const handleSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSize(e.target.value);
        setProductInfo(e.target.value, color, shape);
    };

    const handleShapeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setShape(e.target.value);
        setProductInfo(size, color, e.target.value);
    };

    const handleColorChange = (value: string) => {
        setColor(value);
        setProductInfo(size, value, shape);
    };

    const setProductInfo = (size: string, color?: string, shape?: string) => {
        const key = [];

        if (color) {
            key.push(color);
        }

        if (size) {
            key.push(size);
        }

        if (shape) {
            key.push(shape);
        }

        const finalKey = key.join(" ");

        const id = product.actions[finalKey].id;
        const price = product.actions[finalKey].price;

        setId(id);
        setPrice(price);

        if (onPriceChange) {
            onPriceChange(price);
        }

        const dimension1 = color || shape || "";

        const variation = createProductVariation({
            id,
            price,
            size,
            dimension1
        });
        sendEcommerceDetail(pageType, product, variation);
    };

    useEffect(() => {
        setId(product.initialAction.id);
        setPrice(product.initialAction.price);
        setSize(product.initialAction.size);
        setShape(product.initialAction.shape);
        setColor(product.initialAction.color);
    }, [product.slug, product]);

    return (
        <form action={`https://shop.thecanarylife.com/cart/add/${id}`} method="POST">
            <h2 className="is-bold">${price}</h2>
            <p>{product.description}</p>

            {/* <h3 className="is-bold">Amount:</h3>
            <Dropdown>
                <option>1 rug</option>
                <option>2 rugs</option>
                <option>3 rugs</option>
                <option>4 rugs</option>
                <option>5 rugs</option>
            </Dropdown> */}

            <h3 className="is-bold">Size:</h3>
            <Dropdown onChange={handleSizeChange} value={size}>
                {product.sizes.map((s, idx) => (
                    <option key={idx}>{s}</option>
                ))}
            </Dropdown>

            {product.colors && product.colors.length && (
                <>
                    <h3 className="is-bold">Color:</h3>
                    <ColorPicker
                        colors={product.colors}
                        onChange={handleColorChange}
                        value={color}
                    />
                </>
            )}

            {product.shapes && product.shapes.length && (
                <>
                    <h3 className="is-bold">Shapes:</h3>
                    <Dropdown onChange={handleShapeChange} value={shape}>
                        {product.shapes.map((s, idx) => (
                            <option key={idx}>{s}</option>
                        ))}
                    </Dropdown>
                </>
            )}

            <Button type="submit" className="is-fullwidth" disabled={true}>
                Add to cart (sold out)
            </Button>

            <div className="buy-usps">
                <span className="is-bold">
                    <TickIcon /> 100% refund in 30 days
                </span>
                <span className="is-bold">
                    <TickIcon /> Free shipping
                </span>
            </div>
        </form>
    );
};
