import React from "react";
import { Link } from "react-router-dom";
import FacebookIcon from "../../assets/icons/Facebook";
import InstagramIcon from "../../assets/icons/Instagram";
import "./footer.scss";

export const Footer: React.FC = () => {
    return (
        <div className="footer">
            <div className="container is-full-width">
                <div className="columns">
                    <div className="column links">
                        <h1>
                            <Link to="/shop/rugs">Rugs</Link>
                        </h1>
                        <h1>
                            <Link to="/transparency">Transparency</Link>
                        </h1>
                        <h1>
                            <Link to="/mission">Mission</Link>
                        </h1>

                        {/* TODO: These pages */}
                        {/* <h1>Blog</h1>
                        <h1>Contact</h1> */}
                    </div>
                    <div className="column is-hidden">
                        {/* TODO: These pages */}
                        {/* <h4>Manage Your Account</h4>
                        <h4>Order Status</h4>
                        <h4>Shipping and Delivery</h4>
                        <h4>Returns</h4> */}
                    </div>
                    <div className="column is-hidden">
                        {/* TODO: These pages */}
                        {/* <h4>Certified Refurbished</h4>
                        <h4>Find a Store</h4>
                        <h4>Dealer Store</h4>
                        <h4>Contact Us</h4> */}
                    </div>
                    <div className="column is-hidden">
                        {/* TODO: These pages */}
                        {/* <h4>Investors</h4>
                        <h4>Work with Canary</h4>
                        <h4>Sign up for email</h4> */}
                    </div>
                </div>
                <div className="columns">
                    <div className="column contacts">
                        <div className="social-media">
                            {/* <TwitterIcon /> */}
                            <a
                                href="https://www.facebook.com/thecanarylife/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FacebookIcon />
                            </a>
                            {/* <YoutubeIcon /> */}
                            <a
                                href="https://www.instagram.com/thecanarylife/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <InstagramIcon />
                            </a>
                        </div>

                        <div className="details">
                            <h3>
                                <a href="tel:833 805 9700">833 805 9700</a>
                            </h3>
                            <h3>
                                <a href="mailto:info@thecanarylife.com">info@thecanarylife.com</a>
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="columns">
                    <div className="column copywrite">
                        <div>
                            <span>© 2019 The Canary Life</span>
                            <span>Legal</span>
                            <span>Privacy statement</span>
                            {/* TODO: These pages */}
                            {/* <span className="is-hidden-touch">Disclaimer</span>
                        <span className="is-hidden-touch">Shipping details</span> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
