import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { RouteComponentProps } from "react-router";
import weWantItBack from "../../assets/about/we-want-it-back.png";
import { PageContext } from "../../providers/PageProvider";
import { usePageView } from "../../utils/use-page-view";
import "./about-page.scss";

const TITLE = "Canary | Mission";

export const MissionPage: React.FC<RouteComponentProps> = (props) => {
    const {
        location: { pathname }
    } = props;
    const { sendDataLayer } = useContext(PageContext);

    usePageView(pathname, TITLE);

    useEffect(() => {
        sendDataLayer("mission-page");
        // eslint-disable-next-line
    }, []);

    return (
        <div id="about" className="mission">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>

            <div className="header"></div>

            <div className="main">
                <section className="section">
                    <div className="container">
                        <div className="columns is-marginless">
                            <div className="column is-8 is-offset-2">
                                <h2 className="h1 is-bold has-side-padding">
                                    We believe that every home should be a healthy nest.
                                </h2>
                                <p className="is-bold has-side-padding">Safe for you</p>
                                <p className="has-side-padding">
                                    Why should you be surrounded by products that make you sick and
                                    make it difficult for you to breathe? Well, it is about time to
                                    say: "stop!" to this madness and demand safer home products.
                                </p>
                                <p className="has-side-padding">
                                    Our products are absolutely healthy for you and your family, as
                                    they undergo rigorous testing beyond what any certification may
                                    ever prove. We design them in collaboration with scientists and
                                    toxicologists to ensure you have the best possible product.
                                </p>
                                <p className="is-bold has-side-padding">Good for the planet</p>
                                <p className="has-side-padding">
                                    We live in a world where we throw things away. But where is
                                    away? Every year, billion square pounds of furniture ends in the
                                    landfill, being the highest source of landfill pollution by
                                    volume. Why should we live this way?
                                </p>
                                <p className="has-side-padding">
                                    At Canary, we make products so they can be reused or recycled
                                    again and again, keeping them out of the landfill. Good for the
                                    planet, good for humankind in the long run.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="again" className="section">
                    <div className="container is-gray">
                        <div className="columns is-marginless">
                            <div className="column">
                                <div className="content">
                                    <h2 className="is-bold">Again and again</h2>
                                    <p>
                                        We love the feeling of the sun on our skin, the wind in our
                                        hair and soft carpet between our toes. What we don't love is
                                        toxic stuff, pollution or waste.
                                    </p>
                                    <p>
                                        That's why our freedom squares are made from polyester
                                        (PET), one of the most recyclable materials on the planet.
                                        We make sure it does not go into the landfill or burnt, and
                                        gets recycled into premium, high quality, soft rugs for you
                                        to love again and again.
                                    </p>
                                </div>
                            </div>
                            <div className="column">
                                <img src={weWantItBack} alt="" />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};
