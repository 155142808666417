import React from "react";
import beans from "../../assets/beans.jpg";
import forest from "../../assets/forest.jpg";
import BloomingFlowerCover from "../../assets/product/blooming-flower/blooming-flower.jpg";
import { LearnMore } from "../../components/LearnMore/LearnMore";

export const LearnMoreContainer: React.FC = () => {
    return (
        <>
            <LearnMore
                title="Awareness = Impact"
                description="We have done a lot of research about indoor air pollution, allergies, and things that can make our health and the planet better. We want to share our knowledge with you so stay tuned for updates!"
                image={forest}
            />
            <LearnMore
                title="We want it back!"
                description="We put a lot of effort making our rugs as pure and recyclable as possible, so they are a precious material to us. If you are ever tired of them, let Canary know it is time to fly home!"
                image={beans}
            />
            <LearnMore
                title="We test for toxicity"
                description="We are obsessed with materials. We love to dive in and know everything about them! As their health and sustainability is our passion, we'll have fun researching while you can get your rug with peace of mind."
                image={BloomingFlowerCover}
            />
        </>
    );
};
