import blooming_flower_context_2 from "./blooming_flower_context_2-1024x682.jpg";
import blooming_flower_context_3 from "./blooming_flower_context_3-1024x682.jpg";
import blooming_flower_detail_1 from "./blooming_flower_detail_1-1024x682.jpg";
import blooming_flower_detail_2 from "./blooming_flower_detail_2-1024x682.jpg";
import blooming_flower_detail_3 from "./blooming_flower_detail_3-1024x682.jpg";
import blooming_flower_detail_edge from "./blooming_flower_detail_edge-1024x682.jpg";
import blooming_flower_top from "./blooming_flower_top-1024x682.jpg";

const images = [
    blooming_flower_context_3,
    blooming_flower_top,
    blooming_flower_detail_2,
    blooming_flower_detail_edge,
    blooming_flower_context_2,
    blooming_flower_detail_1,
    blooming_flower_detail_3
];

export default images;
