import React, { useState } from "react";
import "./color-picker.scss";

interface ColorPickerProps {
    colors?: string[];
    value?: string;
    onChange?: (value: string) => void;
}
export const ColorPicker: React.FC<ColorPickerProps> = ({
    value,
    onChange,
    colors = [],
    children
}) => {
    const [selectedColor, selectColor] = useState(value || colors[0]);

    const handleClick = (color: string) => {
        selectColor(color);

        if (onChange) {
            onChange(color);
        }
    };

    const renderColors = colors.map((c, i) => {
        return (
            <div
                className={c === selectedColor ? "color-border selected" : "color-border"}
                key={i}
                onClick={() => handleClick(c)}
            >
                <div className={`color ${c.toLowerCase()}`}></div>
            </div>
        );
    });

    return (
        <div className="color-picker">
            <div className="colors">{renderColors}</div>

            {children}
        </div>
    );
};
