import * as React from "react";

const RightArrowIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg viewBox="0 0 174.83 57.04" width="1em" height="1em" {...props}>
            <path
                d="M2.83 28.52h165.19"
                fill="none"
                stroke="#010101"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5.67}
            />
            <path
                d="M129.27 55.73a2.83 2.83 0 01.87-3.91l36.58-23.3-36.58-23.3a2.84 2.84 0 113.05-4.78l40.33 25.69a2.84 2.84 0 010 4.78L133.19 56.6a2.85 2.85 0 01-3.92-.87z"
                fill="#010101"
            />
        </svg>
    );
};

export default RightArrowIcon;
