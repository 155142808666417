import React from "react";
import { Link } from "react-router-dom";
import "./button.scss";

interface ButtonProps {
    to?: string;
    className?: string;
    type?: "submit";
    disabled?: boolean;
}
export const Button: React.FC<ButtonProps> = ({
    children,
    disabled = false,
    className = "",
    to: to = "#",
    type
}) => {
    const klasses = ["button is-primary"];

    if (className.length) {
        klasses.push(className);
    }

    if (type) {
        return (
            <button type="submit" className={klasses.join(" ")} disabled={disabled}>
                {children}
            </button>
        );
    }

    return (
        <Link to={to} className={klasses.join(" ")}>
            {children}
        </Link>
    );
};
